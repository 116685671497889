import React from "react"

import { Layout, Seo, classes } from "../../layout"
import { MdHotel } from "react-icons/md"
import { ImWrench } from "react-icons/im"
import { BiBuildingHouse } from "react-icons/bi"

import { FindOutMoreButton, img } from "../../components"

export default function IndexPage(props) {
  const { heading } = classes
  const section = "p-6 py-8 md:px-16 max-w-5xl mx-auto"
  const sub_heading =
    "flex justify-left items-center mb-4 text-base sm:text-xl font-bold"
  const paragraph = "my-6"

  return (
    <Layout path={props.path}>
      <Seo title="Trailguide Presence" />

      <div className={section + " mt-24"}>
        <h1 className={heading}>Trailguide Presence</h1>
        <p className="py-2">
          Trailguide is used by hundreds of thousands of people to find and
          share trails and tours to ride. It is also used extensively by people
          to discover new areas to experience.
        </p>
        <p className="py-2">
          If you hare a tourist resort or another local business that offers
          services to visitors and guests, you can have your business added to
          the map and tour descriptions in the Trailguide app.
        </p>
        <p className="py-2">
          If you are an official regional tourist company or tourist destination
          you can add your presence to the map of the Trailguide App so users
          see your region or destination and the activities you offer.
        </p>
      </div>

      <div id="service" className={section}>
        <div className={sub_heading}>
          <Icon>
            <ImWrench className="w-full h-full" />
          </Icon>
          <div>Adding your local business</div>
        </div>
        <p className={paragraph}>
          If you have a small hotel, campsite, restaurant, café, bar, b&b,
          shuttle service, guiding service, sports shop or bike repair shop you
          can be visible in the Trailguide App.
        </p>
        <p className={paragraph}>
          You will be visible on the map with a symbol representing your main
          service from <b>zoom-level 12</b> and on every activity page{" "}
          <b>within 20km</b> of your business. When the user clicks on the
          symbol a popup box will display information about your business, what
          you offer, and details such as email, phone number, web site, facebook
          page, and driving directions.
        </p>
        <p className={paragraph}>
          The price for visibility as a local service from zoom-level 12 is{" "}
          <b>€400 per year</b>.
        </p>

        <MoreButton href="https://trailguide.net/order" target="trailguide.net">
          Add your business to Trailguide
        </MoreButton>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/2">
            <img
              alt="Local service on the map"
              src={img("/website/local-service-phone-map-android.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The bike repair shop Tretterud Sport shown on the map as a repair
              shop symbol. The information popup is shown when the symbol is
              clicked. Clicking the icons in the popup will open your webpage,
              facebook page, email program with email filled in, driving
              directions on google maps, or initiate a phone call.
            </div>
          </div>
          <div className="sm:w-1/2">
            <img
              alt="Local service in activity page"
              src={img("/website/local-service-phone-page-android.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Your business will be listed on every page describing activities
              within 20km of your location. It will even say how far away from
              the activity it is.
            </div>
          </div>
        </div>
      </div>

      <div id="resort" className={section}>
        <div className={sub_heading}>
          <Icon>
            <MdHotel className="w-full h-full" />
          </Icon>
          <div>Adding your resort</div>
        </div>
        <p className={paragraph}>
          If you have larger hotel or a tourist resort you can add it to the
          trailguide map to let people find you. You will be visible from{" "}
          <b>zoom-level 11</b> and the symbol will have your logo.
        </p>
        <p className={paragraph}>
          By default popup box will display information about your resort if the
          user clicks on the symbol. It will show what you offer, and details
          such as email, phone number, web site, facebook page, and driving
          directions.
        </p>
        <p className={paragraph}>
          You can also combine the resort visibility with your own{" "}
          <b>landing page</b>, in wich case the landing page will open when the
          user clicks the symbol.
        </p>
        <p className={paragraph}>
          The price for visibility as a resort from zoom-level 11 is{" "}
          <b>€800 per year</b>.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3">
            <img
              alt="Resort on the map"
              src={img("/website/trailguide-phone-resort.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The mountain hotel Storefjell Resort shown on the map with their
              own logo. If you pair resort visibility with a landing page, the
              landing page will open if the resort icon is clicked.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - top"
              src={img("/website/trailguide-phone-landingpage-storefjell.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The landing page will show details about the resort and the
              services it offers. It also features a cover image and a textual
              description.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - content"
              src={img(
                "/website/trailguide-phone-landingpage-storefjell2.jpeg"
              )}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The landing page will further contain a map and list of available
              activities close to the resort.
            </div>
          </div>
        </div>
      </div>

      <div id="destination" className={section}>
        <div className={sub_heading}>
          <Icon>
            <BiBuildingHouse className="w-full h-full" />
          </Icon>
          <div>Adding your tourist destination</div>
        </div>
        <p className={paragraph}>
          If you are an official tourist destination company, you can add it to
          the trailguide map to let people see you. You will be visible from{" "}
          <b>zoom-level 9</b> and the symbol will feature your own logo.
        </p>
        <p className={paragraph}>
          Combining the destination visibility with your own <b>landing page</b>{" "}
          will open the landing page when the user clicks the symbol on the map.
        </p>
        <p className={paragraph}>
          The price for visibility as a resort from zoom-level 9 is{" "}
          <b>€1.500 per year</b>.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3">
            <img
              alt="Resort on the map"
              src={img("/website/trailguide-destination-gol.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The destination of Gol in Hallingdal is shown on the map where the
              tourist office is lcoated, right in the center of the village.
              Right next to it three three local services are shown.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - top"
              src={img("/website/trailguide-destination-gol-landingpage.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The landing page gives access to the official tours and activities
              available for the destination, and the user can easily find
              activities that suits them by using a category filter.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - content"
              src={img("/website/trailguide-destination-gol-services2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              As a destination you can also list local services such as hotels,
              restaurants, bike shops and guiding services on your landing page.
            </div>
          </div>
        </div>
      </div>

      <div id="region" className={section}>
        <div className={sub_heading}>
          <Icon>
            <BiBuildingHouse className="w-full h-full" />
          </Icon>
          <div>Adding your tourist region</div>
        </div>
        <p className={paragraph}>
          If you are an official tourist region company, you can add it to the
          trailguide map to let people see you. You will be visible from{" "}
          <b>zoom-level 7</b> and the symbol will feature your own logo.
        </p>
        <p className={paragraph}>
          Combining the region visibility with your own <b>landing page</b> will
          open the landing page when the user clicks the symbol on the map.
        </p>
        <p className={paragraph}>
          The price for visibility as a resort from zoom-level 7 is{" "}
          <b>€3.000 per year</b>.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3">
            <img
              alt="Resort on the map"
              src={img("/website/trailguide-region-rides2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The region of Hallingdal is represented as Hallingdal Rides and is
              shown on the map in Hallingdal with their logo.
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - top"
              src={img("/website/trailguide-region-rides-landingpage.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              Clicking on the region symbol on the map will open the region's
              landing page (requires a license to the landing page module).
            </div>
          </div>
          <div className="sm:w-1/3">
            <img
              alt="Landing page - content"
              src={img("/website/trailguide-region-rides-landingpage2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="my-8 px-4 text-sm mx-auto max-w-sm italic">
              The landing page displays official activities and tours in the
              region and the user can filter for the category he or she wants.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Icon(props) {
  return (
    <div className="w-8 h-8 p-1 sm:w-10 sm:h-10 sm:p-2 mr-4 bg-blue-500 text-blue-100 rounded-full">
      {props.children}
    </div>
  )
}

function MoreButton(props) {
  return <FindOutMoreButton className="my-4" {...props} />
}
